.edi-modal-preview {
	padding: 0 !important;
	overflow: hidden;
	background-color: #FFFFFF;
	box-shadow: 0 4px 8px rgba(30, 54, 182, 0.1);
	border-radius: 10px;

	.edi-modal-preview-content {
		text-align: center;
		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}

	.edi-modal-preview-header {
		box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
		padding: 0 20px !important;
		margin-bottom: 10px!important;

		.edi-modal-preview-buttons {
			.x-btn-icon-el:hover {
				color: #1D252C;
			}

			.x-btn:hover {
				.x-btn-icon-el {
					color: #1D252C;
				}

				&::after {
					content: '';
					display: inline-block;
					position: absolute;
					-moz-border-radius: 100%;
					-webkit-border-radius: 100%;
					border-radius: 100%;
					background-color: #0000001a;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
				}
			}
		}

		.edi-modal-preview-label {
			div {
				@include typography__heading_02();
				overflow: hidden;
				-o-text-overflow: ellipsis;
				text-overflow: ellipsis;
				white-space: nowrap;
				cursor: default;
			}
		}

	}
}