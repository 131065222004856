@use '@UIkit/styles/typography/_text.scss';
@import '@UIkit/styles/global/Colors/Variables.scss';

$this: '.ui-check-label';

#{$this} {
	display: flex;
	align-items: center;
	gap: 8px;
	color: $color-grey-50;

	&-icon {
		font-family: Material Icons;
		font-size: 24px;
		line-height: 24px;
	}

	&.active {
		#{$this}-icon {
			color: $color-success;
		}
		#{$this}-text {
			color: $color-dark;
		}
	}

	&.error {
		#{$this}-icon {
			color: $color-error;
		}
		#{$this}-text {
			color: $color-dark;
		}
	}
}
