.edi-viewport {
	.x-menu {
		.x-menu-item {
			.x-menu-item-link {
				.x-menu-item-text {
					@include typography__body-short_01();
				}
			}
		}

		&.edi-user-menu {
			.x-menu-item-link {
				.x-menu-item-text {
					@include typography__body-short_02();
				}
			}
		}
	}
}