.ui-carousel-cards-item {
	margin: 0px 8px;

	.edi-ediweb-banner-img {
		img {
			width: 292px;
			height: 140px;

			&:hover {
				filter: drop-shadow(0px 2px 4px #1d252c3d);
				transition: all 0.3s ease-in;
			}
		}
	}
}
