@import '@UIkit/styles/typography/text';
@import '@UIkit/styles/global/Colors/Variables.scss';
@import '@UIkit/components/fields/Combobox/Combobox.scss';

.ui-comboboxfield-certs {
  @extend .ui-comboboxfield;

  &-desc {
    @include typography__caption_01;
    color: $color-grey-50;
  }
}

.ui-boundlist {
  &-item {
    &-inner {
      margin-bottom: 4px;
    }
  }
}