// Override стилей компонента js/components/HeaderControls/HeaderControls.scss

@import '@UIkit/styles/global/Colors/Variables';

.ui-headercontrols {
	background-color: $color-white;

	.ui-header-logo {
		background-image: url(@Themes/ediweb/images/new_ediweb_logo.svg);
		background-size: auto 16px;
	}

	.ui-button {
		.ui-button-button {
			color: $color-dark;

			&:after {
				color: inherit;
			}
			.ui-button-glyph {
				color: inherit;
			}
		}

		&-over,
		&-menu-active {
			.ui-button-button {
				background-color: $color-blue-opacity-10;
			}
		}

		&-nav {
			.ui-button-button {
				color: $color-blue;
			}
		}
	}
}
