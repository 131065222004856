.edi-tour-manager-show-tips-button {
	position: absolute;
	bottom: 0;
	margin: 10px;
}

.edi-ediweb-tour-modal {
	$this: &;

	.ui-modal-body {
		background-color: #ffffff;
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;
	}


	&.animated-motion {
		transition: all ease-in-out 0.3s;
	}

	&-mask {
		&-wrapper {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
		}

		&-fragment {
			transition: all ease-in-out 0.3s;
			position: absolute;
			background-color: #1D252C;
			opacity: 0.2;
		}
	}

	&-stepper {
		margin-bottom: 16px;
		line-height: 8px;
		height: 8px;

		div {
			display: inline-block;
			width: 8px;
			height: 8px;
			margin-right: 8px;
			background-color: #D2D3D5;
			border-radius: 4px;

			&.active {
				width: 32px;
				background-color: #3D7DCA;
			}
		}
	}

	&-content {
		&-title {
			margin-bottom: 16px;
			@include typography__heading_02();
		}

		&-text {
			@include typography__body-short_01();
		}
	}

	.x-toolbar-footer.x-toolbar {
		padding: 24px;
		border-bottom-left-radius: 8px;
		border-bottom-right-radius: 8px;
	}

	&-arrow-top {
		overflow: visible;

		&::after {
			content: '';
			position: absolute;
			top: -7px;
			left: 50%;
			transform: translate(-50%, 0);
			border-left: 8px solid transparent;
			border-right: 8px solid transparent;
			border-bottom: 8px solid white;
		}
	}

	&-arrow-bottom {
		overflow: visible;

		&::after {
			content: '';
			position: absolute;
			bottom: -7px;
			left: 50%;
			transform: translate(-50%, 0);
			border-left: 8px solid transparent;
			border-right: 8px solid transparent;
			border-top: 8px solid white;
		}
	}

	&-arrow-top,
	&-arrow-bottom {
		&#{$this}-arrow-align-start {
			&::after {
				left: 16px;
			}
		}
		&#{$this}-arrow-align-middle {
			&::after {
				left: 50%;
			}
		}
		&#{$this}-arrow-align-end {
			&::after {
				left: calc(100% - 16px);
			}
		}
	}

	&-arrow-right {
		overflow: visible;

		&::after {
			content: '';
			position: absolute;
			top: 50%;
			right: -7px;
			transform: translate(0, -50%);
			border-left: 8px solid white;
			border-top: 8px solid transparent;
			border-bottom: 8px solid transparent;
		}
	}

	&-arrow-left {
		overflow: visible;

		&::after {
			content: '';
			position: absolute;
			top: 50%;
			left: -7px;
			transform: translate(0, -50%);
			border-right: 8px solid white;
			border-top: 8px solid transparent;
			border-bottom: 8px solid transparent;
		}
	}

	&-arrow-right,
	&-arrow-left {
		&#{$this}-arrow-align-start {
			&::after {
				top: 16px;
			}
		}
		&#{$this}-arrow-align-middle {
			&::after {
				top: 50%;
			}
		}
		&#{$this}-arrow-align-end {
			&::after {
				top: calc(100% - 16px);
			}
		}
	}
}