.edi-viewport {
  .drag-file.ediweb-file-dropper {
	min-height: auto;

	.drag-file-uploaded-file {
	  margin-top: 0;
	}
  }

  .drag-file-read-only.ediweb-file-dropper {
	.drag-file-uploaded-file {
	  margin-top: 0;
	}
  }
}
