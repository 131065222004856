.#{$prefix}-form {
	&-maxwidth {
		max-width: 1200px;
	}
}

.x-trigger-cell {
	min-width: unset;
	vertical-align: middle;
	margin-left: unset;
}

.x-border-layout-ct {
	background-color: #f2f4f8;
}

.edi-info-block-row {
	position: relative;
}

//.edi-ediweb-grey-label .edi-fieldset-label {
//	color: grey;
//	margin-top: -3px;
//}

.edi-ediweb-logo-combo {
	.x-form-text {
		background-size: auto 24px !important;
		background-repeat: no-repeat !important;
		background-position: 8px center !important;
		padding-left: 48px !important;
	}

	input.coreplat {
		background-image: url('images/projectLogo/coreplat.svg');
	}

	input.factorplat {
		background-image: url('images/projectLogo/factorplat.svg');
	}

	input.linkserver {
		background-image: url('images/projectLogo/linkserver.svg');
	}

	input.edi_agent {
		background-image: url('images/projectLogo/edi_agent.svg');
	}

	input.edi_courier {
		background-image: url('images/projectLogo/edi_courier.svg');
	}

	input.one_c_module {
		background-image: url('images/projectLogo/1c_modul.svg');
	}

	input.ediweb {
		background-image: url('images/projectLogo/ediweb.svg');
		color: transparent !important;
		width: 72px !important;
		background-size: 85% !important;
	}

	input.dropcat {
		background-image: url('images/projectLogo/dropcat.svg');
	}
}

.edi-module-panel .x-docked-bottom {
	height: 56px;
	border: none !important;
}

.edi-main-modules-panel {
	box-shadow: -6px 8px 8px rgb(187 189 191 / 25%);
	border-radius: 4px;

	.edi-module-add {
		.x-toolbar,
		.x-docked-bottom {
			//box-shadow: 0 0 8px rgb(0 0 0 / 10%);

			.x-btn {
				margin-right: 24px;
			}
		}
	}
}

.edi-ediweb-btn-with-menu {
	width: 1.2%;

	.x-btn-inner-center {
		float: left;
	}

	.x-btn-button::before {
		position: absolute;
		left: 86%;
		color: #ffffff;
		content: '';
		width: 1px;
		height: 32px;
		background: #6497d5;
	}

	.x-btn-button::after {
		content: '';
		position: absolute;
		top: 40%;
		left: 92%;
		border-top: solid 5px #fff;
		border-left: solid 5px transparent;
		border-right: solid 5px transparent;
	}
}

.edi-ediweb-btn-with-menu-arrow-up {
	.x-btn-button::after {
		content: '';
		position: absolute;
		top: 40%;
		left: 92%;
		border-top: solid 5px #fff;
		border-left: solid 5px transparent;
		border-right: solid 5px transparent;
		transform: rotate(180deg);
	}
}

// .edi-main-modules-panel .edi-module-tab-button-with-icon .x-tab-inner {
// 	max-width: 345px;
// }

.edi-ediweb-menu-icon {
	padding-left: 10px;
	top: -2px;
}

.edi-ediweb-menu-action {
	.x-btn-arrow {
		display: flex;
	}

	.x-btn-arrow::after {
		content: '\e5c5';
		font-family: 'Material Icons';
		font-size: 24px;
		font-style: normal;
		font-weight: normal;
		padding-left: 6px;
		top: -2px;
		position: relative;
		text-decoration: inherit;
		color: #8e9295;
	}
}

.edi-ediweb-menu-action.x-btn-over {
	padding-right: 2%;
}

.x-tip-default {
	background: #ffffff;
	border: 1px solid #1d252c;
	padding: 4px 8px;
	border-radius: 0;

	.x-tip-header {
		display: none !important;
	}
}

.x-tip-form-invalid {
	background: #ffffff;
	border: 1px solid #1d252c;
	padding: 4px 8px;
	border-radius: 0;
}

.x-tip-body-form-invalid {
	padding: 0 8px 4px 22px !important;
	margin-top: 3px;
}

BODY.edi-viewport .x-tip-form-invalid {
	width: 328px !important;
}

.edi-viewport {
	a.edi-ediweb-white-button.x-btn.x-btn-default-small {
		background: #ffffff !important;
		box-shadow: 0 4px 8px rgba(30, 54, 182, 0.1) !important;
		border: 1px solid #1d252c;
		padding: 8px 16px !important;
		height: 34px !important;

		.x-btn-inner {
			color: #1d252c !important;
		}
	}
}

.edi-ediweb-submit-button {
	.x-btn-wrap {
		text-transform: none !important;
		top: -3px !important;
	}
}

//.edi-grid-top-bar-other-actions-menu .x-menu-item-separator {
//	height: 24px;
//	border-top: 1px solid #E8E9E9;
//	@include typography__caption_01();
//	padding: 8px 33px 0 16px;
//	color: #8e9295;
//	cursor: default;
//}

.x-btn-default-small-icon-text-left .x-btn-inner {
	line-height: 20px;
	padding-left: 25px;
}

.x-column-header-over .x-column-header-trigger,
.x-column-header-open .x-column-header-trigger {
	display: none;
}

BODY.edi-viewport {
	.x-item-disabled {
		.x-form-item-label,
		.x-form-field,
		.x-form-display-field,
		.x-form-cb-label,
		.x-form-trigger,
		.x-form-trigger-wrap {
			border-color: #bbbdbf;
		}
	}
}

.edi-ediweb-cert-status {
	&-label {
		@include typography__heading_01();
	}
}

.edi-ediweb-cert-status-container {
	top: 2px;
	position: relative;
}

.edi-module-panel {
	.x-panel-body {
		background-color: #fff;
	}
}
.ediweb-application_to_fns-subtitle-button {
	cursor: default;
	padding-left: 16px;
	&.x-btn-over {
		background-color: transparent !important;
	}
}

.edi-ediweb-details-block-certificate-application-modal {
	.edi-ediweb-fields-block-title {
		margin-bottom: 8px !important;
	}

	.edi-ediweb-detail-label {
		margin-bottom: 8px !important;
	}
}

.ediweb-productType-column {
	.edi-ediweb-project-item {
		display: inline-block;
		padding-right: 16px;
		.logo {
			margin: 9px 0;
			position: relative;
			top: 6px;
		}
	}
}

.ediweb-row-block-remove_button {
	top: -1px !important;
	margin-right: 16px !important;
}

.ediweb-row-block-add_button {
	top: 2px !important;
}

.ediweb-productInfos-read-only-form-line {
	height: 1px;
	background: $color-grey-20;
	transform: matrix(1, 0, 0, -1, 0, 0);
	top: -8px;
}

.ediweb-internal-rows-panel {
	.edi-ediweb-project-item {
		top: 7px;
		position: relative;
	}
}

.ediweb-employee-dc-form {
	.row-block-buttons-container {
		padding-top: 34px;
	}
}

.ediweb-control-center-title {
	.x-title-text {
		@include typography__heading_02();
	}
}

.edi-ediweb-left-align-action-button {
	.ui-button-button {
		justify-content: flex-start;
		.ui-button-inner {
			font-weight: 400;
		}
	}
}

.ediweb-form-panel-description {
	.ui-label-text:after {
		content: '\e7f4';
		font-family: 'Material Icons';
		font-size: 20px;
		left: 8px;
		top: 4px;
		position: relative;
	}
}
