.edi-ediweb-progress-bar {
	&-container {
		position: relative;
		background-color: #E2ECF7;
		border-radius: 8px;
		&-progress {
			position: relative;
			background-color: #3D7DCA;
			border-radius: 8px;
		}
	}
}