// zIndex позиция слоев во viewport
.edi-viewport {
	position: relative !important;

	&-header {
		z-index: 1 !important;
	}

	&-footer {
		z-index: 2 !important;
	}

	& .edi-main-panel {
		& .edi-main-modules-panel {
			z-index: 1 !important;
		}

		& .edi-navigation-panel {
			z-index: 2 !important;
		}

		& .edi-main-header-panel {
			z-index: 3 !important;
		}
	}
}
//окно логина должно быть выше всего и блокировать весь вьюпорт
BODY.edi-viewport,
BODY.edi-viewport-with-background.edi-login-form-displayed {
	.edi-login-form-window {
		z-index: 990000 !important;
	}
}