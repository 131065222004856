.edi-ediweb-employees-status {
	.edi-state-container {
		position: relative;
		top: 8px;

		span {
			@include typography__body-short_01();
		}
	}
}

.edi-ediweb-route-info {
	margin-bottom: 0px !important;
	.info-container {
		margin-bottom: 24px !important;
		padding: 16px 24px 16px 18px;
		background: #fdf3e5;
		border-radius: 8px;
		position: relative;
		align-items: center;
		justify-content: center;
		display: flex;

		.text {
			@include typography__body-short_01();
			color: #1d252c;
		}

		.icon::before {
			@include icon('\e000');
			color: #ff9400;
			line-height: 20px;
			font-size: 20px;
			margin-right: 18px;
		}
	}
}
.employee-products-access {
	.x-grid-cell-inner {
		display: flex;
		gap: 16px;
	}
}
