.edi-ediweb-connecting-dropcat-modal {
	$this: &;

	.x-form-cb-label {
		margin-top: 0;

		#{$this}-checkbox {
			&-title {
				@include typography__heading_01();
				padding-left: 9px;
			}

			&-subtitle {
				@include typography__body-short_01();
				color: $color-grey-50;
				padding-left: 9px;
				padding-top: 5px;
			}
		}
	}
}