.#{$global}-navigation-panel {

  .categoryItem {
    .categoryItemHeader,
    .subCategoryItemHeader {
      .x-title-text {
        @include typography__body-short_01();
      }
    }
  }

  &-closed {
    .active {
      .categoryItemHeader {
        width: 40px!important;
      }
    }
  }
  &-hover {
    .categoryItemHeader {
      background: rgba(61, 125, 202, 0.1);
      border-radius: 3px;
      width: auto !important;
    }
  }

  .active {
    .categoryItemHeader {
      background: rgba(61, 125, 202, 0.1);
      border-radius: 3px;
      width: auto;
    }
  }

  .x-title {
    &-glyph {
      width: auto !important;
      height: auto !important;
      color: #3d7dca !important;
    }
  }

  .edi-navigation-panel-inner-body {
    background-image: none;
  }
}