@import '@UIkit/styles/global/Colors/Variables.scss';

.vertical-stepper {
	.step {
		$step-padding-left: 40px;
		$step-padding-right: 60px;
		$step-item-padding-left: 40px;

		width: auto;
		display: block;
		padding: 0 $step-padding-right 0 $step-padding-left;

		&:last-child {
			& > .step-item-container {
				&:before {
					content: none;
				}
			}
		}

		&-container {
			display: flex;
			flex-direction: column;
		}

		&-item {
			&-container {
				padding-left: $step-item-padding-left;
				position: relative;
				width: auto;
				display: block;

				&:before {
					position: absolute;
					top: 11px;
					left: 11px;
					content: '';
					z-index: 1;
					display: block;
					width: 2px;
					height: 100%;
					background: #e8e9e9;
				}
			}
		}

		&_name {
			position: relative;
			color: #8e9295;
		}

		&_text {
			&_grey {
				display: block;
				@include typography__caption_01();
				color: #8e9295;
				padding-bottom: 4px;
			}

			&_black {
				display: block;
				@include typography__body-short_01();
				color: #1d252c;
				padding-bottom: 4px;
			}
		}

		&_index {
			display: block;
			@include typography__body-short_02();
			width: 24px;
			height: 24px;
			line-height: 24px;
			z-index: 2;
			text-align: center;
			position: absolute;
			top: 0;
			left: 0;
			background-color: #d2d3d5;
			border-radius: 50px;
			color: #fff;
		}

		&_status {
			&__finished {
				.step_index {
					background-color: #3d7dca;
					color: white;
				}
				.step-item-container {
					&:before {
						background: #3d7dca;
					}
				}
			}

			&__active {
				.step_index {
					background-color: #3d7dca;
					color: white;
				}
			}
		}

		.top-offset {
			padding-top: 12px;
		}

		.reject-reason.fieldset-padding.edi-ediweb-notification-block {
			position: relative;
			left: calc(-1 * ($step-padding-left + $step-item-padding-left));
			top: 10px;
			width: calc(100% + $step-padding-left + $step-item-padding-left + $step-padding-right);
			padding-left: calc($step-padding-left + $step-item-padding-left);
		}

		.executor {
			padding-top: 8px;
			padding-bottom: 16px;

			.executor-comment {
				.fieldset-label {
					@include typography__body-short_02();
				}
				padding-bottom: 16px;
			}
		}
		.step_all_employees {
			position: relative;
		}

		.signer-comment {
			.fieldset-label {
				@include typography__body-short_02();
			}
			background-color: $color-grey-5;
			width: 427px;
			padding: 16px 0px 16px 82px;
			left: -82px;
			margin-top: 16px;
			z-index: 0;
			position: relative;
		}

		.step_status {
			display: block;
			@include typography__body-short_02();
			color: #1d252c;

			&.status {
				&-REJECTED {
					&::before {
						content: '\e14b';
						font-family: 'Material Icons', sans-serif;
						top: 5px;
						padding-right: 6px;
						font-size: 20px;
						position: relative;
						color: #e82000;
					}
				}

				&-REASSIGN {
					&::before {
						content: '\e163';
						font-family: 'Material Icons', sans-serif;
						top: 5px;
						padding-right: 6px;
						font-size: 20px;
						position: relative;
						color: #6497d5;
					}
				}

				&-APPROVAL,
				&-SIGN,
				&-SIGNED {
					&::before {
						content: '\e5ca';
						font-family: 'Material Icons', sans-serif;
						top: 5px;
						padding-right: 6px;
						font-size: 20px;
						position: relative;
						color: #00ac2d;
					}
				}

				&-SIGN_AWAITING,
				&-APPROVAL_AWAITING {
					&::before {
						content: '\e5ca';
						font-family: 'Material Icons', sans-serif;
						top: 5px;
						padding-right: 6px;
						font-size: 20px;
						position: relative;
						color: #d2d3d5;
					}
				}
			}
		}

		&.x-item-selected {
			.step_name {
				color: #1d252c;
			}

			.step_index {
				background-color: #6497d5;
			}
		}
	}
}
