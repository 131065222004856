.edi-viewport {
	//грид согласований - стили строк у которых исполнитель это текущий юзер
	.edi-ediweb-approvals-grid {
		.x-grid-view {
			.x-grid-item {
				.x-grid-row.edi-ediweb-approvals-grid-executor-self {
					.x-selmodel-column {
						border-left: 4px solid #3d7dca;
						padding: 0 12px 0 8px;
					}
				}
			}
		}
	}

	.ui-table-empty-text-with-image-icon {
		&-document-statuses {
			background-image: url('images/emptyGrids/no_notices.png');
		}
		&-approval-documents {
			background-image: url('images/emptyGrids/no_approval_documents.png');
		}
		&-invitation {
			background-image: url('images/emptyGrids/no_invites.png');
		}
		&-archive-documents {
			background-image: url('images/emptyGrids/no_archive_doc.png');
		}
		&-archive-approval {
			background-image: url('images/emptyGrids/no_archive_redirect.png');
		}
		&-archive-poa {
			background-image: url('images/emptyGrids/no_archive_poa.png');
		}
		&-powers-attorney {
			background-image: url('images/emptyGrids/no_powers_of_attorney.png');
		}
		&-certificates {
			background-image: url('images/emptyGrids/no_employee_sert.png');
		}
		&-application-fns {
			background-image: url('images/emptyGrids/no_apply.png');
		}
		&-credentials {
			background-image: url('images/emptyGrids/no_credentials.png');
		}
		&-employees {
			background-image: url('images/emptyGrids/no_empl.png');
		}
		&-routes {
			background-image: url('images/emptyGrids/no_routes.png');
		}
		&-subdivisions {
			background-image: url('images/emptyGrids/no_subdivisions.png');
		}
	}
}
