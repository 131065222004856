.edi-viewport {
	.x-window-header-default {
		.x-title-text-default {
			@include typography__heading_02();
		}

		.x-box-inner {
			.x-box-target {
				.x-tool {
					margin: 3px 0 0px -2px !important;
					opacity: 0.5;
					grid-template-columns: repeat(12, 1fr);

					&.x-tool-over {
						opacity: 0.6;
					}

					.x-tool-close {
						background: url('images/icons/close.svg') center center no-repeat !important;
						font-size: 0 !important;
					}
				}
			}
		}
	}

	.x-window-default {
		.x-window-header {
			> .x-box-inner {
				height: 27px !important;
			}
		}
	}
}

.edi-modal-product-data-selector {
	.x-toolbar-footer {
		box-shadow: 0 4px 24px rgba(29, 37, 44, 0.12);
	}
}

.edi-viewport {
	.x-window {
		&-default {
			box-shadow: 0 8px 48px rgba(29, 37, 44, 0.16);
		}

		border-radius: 8px !important;
		box-shadow: 0 8px 48px rgba(29, 37, 44, 0.16);

		&-header {
			background-color: #fff !important;
			border: none !important;
			padding: 0 !important;
			margin: 16px 24px 14px 24px !important;

			& .x-tool {
				top: 0 !important;
				margin: 0 !important;

				&-img {
					display: block;

					&::before {
						display: block;
						width: 16px;
						line-height: 16px;
						text-align: center;
					}
				}

				&-close {
					&::before {
						content: '\f00d';
					}
				}

				&-over {
					border-radius: 50%;
					background: rgba(29, 37, 44, 0.1);
				}
			}
		}

		&-body {
			margin: 0;
			padding: 0;
			border-radius: 8px;
		}

		&-item {
			//padding: 0;
			//margin: 0;
		}

		&-text {
			table-layout: fixed !important;
			width: fit-content;
			padding: 0;
			margin: 0 16px;
			@include typography__body-short_01();
			color: #1d252c;
		}

		& .x-toolbar {
			margin: 0;
			padding: 0;

			> .x-box-inner {
				overflow: visible;
			}

			&-footer {
				padding: 16px 24px;
				background: none;
			}
		}
	}
}

// Грязные overwrite, нужно избавляться
BODY.edi-viewport .x-tool-default {
	width: auto !important;
	height: auto !important;
}

BODY.edi-viewport .x-tool-after-title {
	margin: 0 !important;
}

.x-form {
	&-display {
		&-field {
			margin: 0 !important;
			padding: 0 !important;
			@include typography__body-short_02();

			& .msg-width-correction {
				height: 0 !important;
				margin: 0 !important;
				padding: 0 !important;
				border-spacing: 0 !important;
				border-collapse: collapse;
			}
		}
	}
}

.x-panel {
	& .x-toolbar {
		border: none;
	}
}

.module-panel {
	& .x-toolbar {
		margin: 0;
		padding: 16px 0;
	}
}

.edi-ediweb-modal-container {
	padding: 0 24px !important;
}

.edi-container-margin {
	.x-window-body {
		height: auto !important;
	}
}

.edi-modal-certificate-selector {
	.x-window-body {
		padding: 0 24px !important;
	}

	.x-toolbar-footer {
		box-shadow: 0 4px 24px rgba(29, 37, 44, 0.12);
	}
}

.edi-ediweb-modal-linked-documents {
	.ui-tree-panel {
		.x-title-text {
			font-size: 16px !important;
		}
	}
}
