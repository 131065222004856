.#{$prefix}-intro {
	border: 0;

	&-left {
		background-color: #F3F4F4 !important;
	}

	&-right {
		overflow: auto;
		padding: 40px 40px 0 40px;
		background-color: #FFF !important;
	}

	&-steps {
		display: flex;
		justify-content: flex-start;

		div {
			width: 8px;
			height: 8px;
			margin-right: 8px;
			background-color: #D2D3D5;
			border-radius: 4px;

			-webkit-transition: all 1s ease-in;
			-moz-transition: all 1s ease-in;
			-o-transition: all 1s ease-in;
			transition: all 1s ease-in;
		}

		&-active {
			width: 32px !important;
			background-color: #3D7DCA !important;
		}
	}

	&-text {
		&-tpl-container {
			margin-top: 40px;

			ul {
				padding-inline-start: 24px;

				li:not(:last-child) {
					padding-bottom: 16px;
				}
			}
		}

		&-tpl-first {
			.#{$prefix}-intro-text-tpl-container {
				margin-top: 0;
				position: relative;
				top: 117px;
				-webkit-transform: translateY(-50%);
				-ms-transform: translateY(-50%);
				transform: translateY(-50%);
			}
		}

		h2,
		h3 {
			@include typography__heading_02();
		}

		p,
		ul {
			@include typography__body-long_01();
		}

		&-tpl-first {
			h2 {
				@include typography__heading_03();
			}

			p,
			ul {
				@include typography__body-short_03();
			}
		}
	}

	&-img {
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;

		img {
			width: calc(100% - 80px);
		}
	}
}