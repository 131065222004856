.#{$prefix}-notification-toasts-container {
	z-index: 50000;//что бы всплывашки были выше сайдбаров
	top: 88px;
	right: 24px;

	.#{$prefix}-toast-wrapper {
		border: none;
		background-color: transparent;
		box-shadow: none;

		.#{$prefix}-notification {
			box-shadow: 0 4px 16px rgba(187, 189, 191, 0.5);

			.#{$prefix}-notification-date {
				display: none;
			}
		}
	}
}