.#{$prefix}-main-panel {
	&-label {
		h1 {
			padding: 0;
			margin: 0;
		}

		margin: 0 0 8px 0;
	}
}

.#{$prefix}-banner-panel {
	margin: 20px 0;

	&-cards {
		&-item {
			img {
				width: 100% !important;
			}
		}
	}
}

.#{$prefix}-promocode-info-container {
	top: -14px;
}
