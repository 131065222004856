::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 10px 10px #d2d3d5;
  border-radius: 5px;
  border: solid 3px transparent;
}

::-webkit-scrollbar-track {
  background: #FFF;
  border: solid 3px transparent;
}