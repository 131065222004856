.edi-agreement-personal-content {
	@include typography__body-long_01();
	padding-left: 35px;

	ol {
		counter-reset: item;
		padding-left: 16px;
	}

	li {
		display: block;
		margin: 16px 0;
	}

	li:before {
		content: counters(item, ".") ". ";
		counter-increment: item;
		margin-left: -30px;
	}

	dl.dash {
		list-style: none;
		margin-left: 0;
		padding-left: 32px;
		margin-top: 6px;
	}

	dl.dash > dt:before {
		display: inline-block;
		content: "—";
		width: 1em;
		margin-left: -1em;
	}

	dt {
		margin: 8px 0;
	}

	li > b {
		@include typography__heading_01();
		display: contents;
	}

	b {
		@include typography__body-short_02();
		margin-left: -14px;
		display: block;
	}

	p {
		margin-left: -14px;
	}
}

.edi-ediweb-agreement-window {
	.x-window-body {
		top: 0 !important;
	}

	.edi-ediweb-bottom-button {
		left: 21px !important;
	}
}