@import '@UIkit/styles/global/Colors/Variables';
.edi-ediweb-tariffs {
	&-client-card {
		&-container {
			background: #ffffff;
			border: 1px solid $color-grey-10;
			border-radius: 8px;
			padding: 16px 16px 12px 16px;

			&-usedUp {
				background-color: $color-error-opacity-5;

				.edi-ediweb-progress-bar {
					&-container {
						background: $color-error-opacity-10;
						&-progress {
							background: $color-error-opacity-10;
						}
					}
				}
			}
			&.error {
				background-color: $color-error-opacity-5;

				.edi-ediweb-progress-bar {
					&-container {
						background: $color-error-opacity-10;
						&-progress {
							background: $color-error-opacity-10;
						}
					}
				}
			}

			&.active {
				filter: drop-shadow(0px 4px 8px rgba(187, 189, 191, 0.25));
			}
			.edi-state-container {
				box-shadow: none;
			}
			.edi-state-container-ACTIVE,
			.edi-state-container-FUTURE,
			.edi-state-container-PROMO {
				border-color: #3d7dca;

				.status-ACTIVE,
				.status-FUTURE,
				.status-PROMO {
					color: #3d7dca;
					font-weight: 400;
				}
			}
			.edi-state-container-SPEND {
				border-color: $color-error;

				.status-SPEND {
					color: $color-error;
					font-weight: 400;
				}
			}
		}
	}

	&-carousel {
		padding-bottom: 36px;
		&-card-container {
			border: 1px solid #f8f8f8;
			box-shadow: 0px 4px 8px rgba(29, 37, 44, 0.12);
			border-radius: 8px;
			padding: 16px;
		}
	}

	&-actual-cards {
		padding-bottom: 24px;
	}
}
