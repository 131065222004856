.#{$prefix}-project {
	// Logo
	&-item {
		width: auto;
		height: auto;

		& .minilogo {
			width: 36px;
			height: 36px;
			padding-top: 8px;
			background-position: center center !important;
			background-size: auto;

			//&.edi {
			//	background: url('images/projectLogo/edi.svg') left center no-repeat;
			//}
			&.coreplat {
				background: url('images/projectLogo/coreplat.svg') left center no-repeat;
			}
			&.factorplat {
				background: url('images/projectLogo/factorplat.svg') left center no-repeat;
			}
			&.linkserver {
				background: url('images/projectLogo/linkserver.svg') left center no-repeat;
			}
			&.edi_agent {
				background: url('images/projectLogo/edi_agent.svg') left center no-repeat;
			}
			&.edi_courier {
				background: url('images/productLogo/edi_courier.svg') left center no-repeat;
			}
			&.one_c_module {
				background: url('images/projectLogo/1c_modul.svg') left center no-repeat;
			}
			&.ediweb {
				background: url('images/projectLogo/ediweb.svg') left center no-repeat;
				width: 72px;
				background-size: 85%;
			}
			&.dropcat {
				background: url('images/projectLogo/dropcat.svg') left center no-repeat;
			}
			&.contract_management {
				background: url('images/projectLogo/contract_management.svg') left center no-repeat;
			}
			&.workcat {
				background: url('images/projectLogo/workcat.svg') left center no-repeat;
			}
			&.prodcat {
				background: url('images/projectLogo/prodcat.svg') left center no-repeat;
			}
			&.procurement_portal {
				background: url('images/projectLogo/procurement_portal.svg') left center no-repeat;
			}
			&.marking {
				background: url('images/projectLogo/marking.svg') left center no-repeat;
			}
			&.premium_support {
				background: url('images/projectLogo/premium_support.svg') left center no-repeat;
			}
			&.affiliate_program {
				background: url('images/projectLogo/affiliate_program.svg') left center no-repeat;
			}
			&.none {
				width: 0px;
			}
		}
		& .logo {
			width: 114px;
			height: 33px;
			background-size: 90% !important;

			&.ediweb_docs {
				background: url('images/productLogo/Ediweb_Docs_logo.svg') left center no-repeat;
			}
			&.edi {
				background: url('images/productLogo/edi.svg') left center no-repeat;
			}
			&.coreplat {
				background: url('images/productLogo/coreplat.svg') left center no-repeat;
			}
			&.factorplat {
				background: url('images/productLogo/factorplat.svg') left center no-repeat;
			}
			&.linkserver {
				background: url('images/productLogo/linkserver.svg') left center no-repeat;
			}
			&.edi_agent {
				background: url('images/productLogo/edi_agent.svg') left center no-repeat;
			}
			&.edi_courier {
				background: url('images/productLogo/edi_courier.svg') left center no-repeat;
			}
			&.dropcat {
				background: url('images/productLogo/dropcat.svg') left center no-repeat;
			}
			&.contract_management {
				background: url('images/productLogo/contract_management.svg') left center no-repeat;
			}
			&.workcat {
				background: url('images/productLogo/workcat.svg') left center no-repeat;
			}
			&.prodcat {
				background: url('images/productLogo/prodcat.svg') left center no-repeat;
			}
			&.procurement_portal {
				background: url('images/productLogo/procurement_portal.svg') left center no-repeat;
			}
			&.marking {
				background: url('images/productLogo/marking.svg') left center no-repeat;
			}
			&.ediweb {
				background: url('images/projectLogo/ediweb.svg') left center no-repeat;
				width: 72px;
				background-size: 100% !important;
			}
		}
		& .notconnected {
			&.coreplat {
				background: url('images/productLogo/notConnected/coreplat.svg') left center no-repeat;
			}
			&.factorplat {
				background: url('images/productLogo/notConnected/factorplat.svg') left center no-repeat;
			}
			&.linkserver {
				background: url('images/productLogo/notConnected/linkserver.svg') left center no-repeat;
			}
			&.edi_agent {
				background: url('images/productLogo/notConnected/edi_agent.svg') left center no-repeat;
			}
			&.edi_courier {
				background: url('images/productLogo/notConnected/edi_courier.svg') left center no-repeat;
			}
			&.dropcat {
				background: url('images/productLogo/notConnected/dropcat.svg') left center no-repeat;
			}
			&.contract_management {
				background: url('images/productLogo/notConnected/contract_management.svg') left center no-repeat;
			}
			&.workcat {
				background: url('images/productLogo/notConnected/workcat.svg') left center no-repeat;
			}
			&.prodcat {
				background: url('images/productLogo/notConnected/prodcat.svg') left center no-repeat;
			}
			&.procurement_portal {
				background: url('images/productLogo/notConnected/procurement_portal.svg') left center no-repeat;
			}
			&.marking {
				background: url('images/productLogo/notConnected/marking.svg') left center no-repeat;
			}
		}
	}
	// Logo

	&-itemlist {
		& .item {
			position: relative;
			padding: 4px 3px 4px 16px;
			margin: 5px 13px;
			background: #fff;
			border: 1px solid #f8f8f8;
			box-sizing: border-box;
			border-radius: 4px;
			filter: drop-shadow(0px 4px 8px rgba(187, 189, 191, 0.25));
		}
	}
}
