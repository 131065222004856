.edi-viewport {
	.#{$prefix}-sidebar {
		background: #FFF;
		box-shadow: 0 10px 30px rgba(29, 37, 44, 0.3);

		&-header {
			position: initial !important;

			&-text {
				display: block;
				white-space: normal;
				@include typography__heading_02();
			}

			& .x-tool {
				top: 0 !important;
			}
		}

		&-body {
			&-content {
				padding-top: 28px;

				.state {
					display: flex;
					padding: 2px 0 12px 40px !important;
				}

				.fieldset {
					padding: 12px 0;

					&-label {
						@include typography__heading_01();
						color: #1D252C;
						margin-bottom: 8px;
					}

					&-text {
						@include typography__body-short_01();
						color: #1D252C;

						.org-data {
							@include typography__caption_01();

							.sub {
								color: #8E9295;
							}
						}
					}

					&.notification {
						margin: 12px 0;
						padding: 0;

						.edi-ediweb-notification-block {
							padding: 24px 60px 24px 40px;
						}
					}
				}

				.fieldset-padding {
					padding-left: 40px;
					padding-right: 60px;
				}
			}
		}

		&-floating-close-button.x-btn.x-btn-default-small {
			width: 40px !important;
			height: 40px !important;
			top: 20px !important;
			right: 4px !important;
			left: unset !important;

			.x-btn-icon {
				justify-content: center;
				align-items: center;

				.x-btn-icon-el {
					width: 20px;
				}
			}

			&.x-btn-over {
				background-color: transparent;

				.x-btn-icon-el {
					color: black;
				}

				&::after {
					content: '';
					display: inline-block;
					position: absolute;
					-moz-border-radius: 100%;
					-webkit-border-radius: 100%;
					border-radius: 100%;
					background-color: #0000001a;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
				}
			}
		}

		&-backdrop {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: rgba(0, 0, 0, 0.4);
		}
	}

	.edi-ediweb-sidebar-buttons {
		padding: 16px 24px;
		box-shadow: 0px -8px 8px rgba(29, 37, 44, 0.04);
	}
}