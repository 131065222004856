.#{$prefix}-project-cards {
	.#{$prefix}-project-card {
		position: relative;
		width: 394px;
		height: 240px;
		background: #fff;
		border: 1px solid #f8f8f8;
		box-sizing: border-box;
		border-radius: 8px;
		margin: 8px;
		padding: 24px;

		&.dropcat-card {
			background-color: rgba(235, 239, 249, 1);
		}
		&.edi-card {
			background-color: rgba(61, 125, 202, 0.15);
		}
		&.linkserver-card {
			background-color: rgba(231, 224, 247, 1);
		}
		&.factorplat-card {
			background-color: rgba(230, 246, 245, 1);
		}
		&.edi_courier-card {
			background-color: rgba(248, 248, 248, 1);
		}
		&.edi_agent-card {
			background-color: rgba(232, 32, 0, 0.05);
		}
		&.premium_support-card {
			background-color: rgba(248, 248, 248, 1);
		}
		&.affiliate_program-card {
			background-color: rgba(248, 248, 248, 1);
		}

		.left {
			height: 172px;
			top: 0px;
			position: relative;
			display: flex;
			flex-direction: column;
			align-content: stretch;

			.edi-state-container {
				flex: none;
				display: flex;
				width: fit-content;
				color: $color-blue;
				align-items: center;
				justify-content: space-around;
				padding: 0px 8px;

				.edi-icon-DONE::before {
					font-size: 24px;
					line-height: 18px;
					right: 2px;
					color: $color-blue;
					position: relative;
					content: '\e876';
					font-family: 'Material Icons', sans-serif;
				}

				.text {
					margin-left: 5px;
					color: $color-blue;
				}
			}

			.title-container {
				display: flex;
				align-content: stretch;
				justify-content: space-between;
				align-items: flex-start;

				.title {
					padding-top: 8px;
					padding-bottom: 8px;
				}
			}

			.content {
				height: 100%;
				padding-right: 50px;
			}

			.buttons {
				left: 0px;
				top: 17px;
				position: relative;
			}
		}

		.logo {
			position: absolute;
			right: 0;
			bottom: 0;
			background-size: contain !important;

			&.coreplat {
				width: 66px;
				height: 76px;
				background: url('images/projectLogo/coreplat.svg') left center no-repeat;
			}
			&.edi {
				width: 66px;
				height: 76px;
				background: url('images/projectLogo/new/edi.svg') left center no-repeat;
			}

			&.factorplat {
				width: 68px;
				height: 70px;
				background: url('images/projectLogo/new/factorplat.svg') left center no-repeat;
			}

			&.linkserver {
				width: 62px;
				height: 70px;
				background: url('images/projectLogo/new/linkserver.svg') left center no-repeat;
			}

			&.edi_agent {
				width: 67px;
				height: 64px;
				background: url('images/projectLogo/new/edi_agent.svg') left center no-repeat;
			}

			&.edi_courier {
				width: 68px;
				height: 67px;
				background: url('images/projectLogo/new/edi_courier.svg') left center no-repeat;
			}

			&.dropcat {
				width: 74px;
				height: 72px;
				background: url('images/projectLogo/new/dropcat.svg') left center no-repeat;
			}

			&.premium_support {
				width: 73px;
				height: 62px;
				background: url('images/projectLogo/new/premium_support.svg') left center no-repeat;
			}

			&.affiliate_program {
				width: 71px;
				height: 64px;
				background: url('images/projectLogo/new/affiliate_program.svg') left center no-repeat;
			}

			&.one_c_module {
				background: url('images/projectLogo/1c_modul.svg') left center no-repeat;
			}

			&.ediweb {
				background: url('images/projectLogo/ediweb.svg') left center no-repeat;
			}

			&.contract_management {
				background: url('images/projectLogo/contract_management.svg') left center no-repeat;
			}

			&.workcat {
				background: url('images/projectLogo/workcat.svg') left center no-repeat;
			}

			&.prodcat {
				background: url('images/projectLogo/prodcat.svg') left center no-repeat;
			}

			&.procurement_portal {
				background: url('images/projectLogo/procurement_portal.svg') left center no-repeat;
			}

			&.marking {
				background: url('images/projectLogo/marking.svg') left center no-repeat;
			}
		}
	}
}
