@use 'sass:math';
@import '@UIkit/styles/global/Colors/Variables';

.#{$prefix}-toasts-container {
	z-index: 10;
	display: flex;
	flex-direction: column;
	align-content: flex-start;
	justify-content: flex-end;
	flex-shrink: 1;

	position: absolute;
	top: 0;
	right: 0;

	background: none;
	border: none;
}

.#{$prefix}-toast-wrapper {
	position: relative;
	margin: 0;
	padding: 4px;
	overflow: hidden;

	width: 392px;
	min-height: 50px;
	height: auto;
	border-color: rgb(157, 147, 147, 0.7);
	border-radius: 4px;
	background-color: rgba(157, 147, 147, 0.5);
	box-shadow: 0 4px 16px rgba(187, 189, 191, 0.5);

	transition: all 1s;

	$closeButtonSize: 40px;
	.#{$prefix}-toast__close-button {
		cursor: pointer;
		position: absolute;
		top: 0;
		right: 0;
		width: $closeButtonSize;
		height: $closeButtonSize;
		color: #8e9295;

		&::after {
			position: absolute;
			transform: translate(50%, 50%);
			width: math.div($closeButtonSize, 2);
			height: math.div($closeButtonSize, 2);
			font-size: math.div($closeButtonSize, 2);
			content: '\e14c';
			font-family: 'Material Icons', sans-serif;
		}

		&:hover::after {
			color: #3d7dca;
		}
	}

	&-animate-close {
		margin-top: 0 !important;
		margin-bottom: 0 !important;
		padding-top: 0 !important;
		padding-bottom: 0 !important;
		min-height: 0 !important;
		height: 0 !important;
		transform: scale(0) !important;
		opacity: 0 !important;

		.#{$prefix}-toast__close-button {
			transition: all 0.3s;
			transform: scale(0);
		}
	}
}

.ui-toast {
	.bold {
		color: $color-white;
	}
}
