.edi-ediweb-confirm {
  .edi-ediweb-button-primary {
    left: 4%!important;
  }
  .edi-ediweb-button-second-action {
    left: 27%!important;
  }

  & .x-toolbar {
    margin: 0;
    padding: 0;

    &-footer {
      padding: 16px 24px 16px 8px!important;
      background: none;
    }
  }

  & .x-window-text {
    width: 392px;
  }
}