.edi-viewport {
	.edi-icon-FILE_DOWNLOAD::before {
		content: '\e2c4';
		font-family: 'Material Icons', sans-serif;
	}

	.edi-icon-REFRESH::before {
		content: '\e028';
		font-family: 'Material Icons', sans-serif;
	}

	.edi-icon-VISIBILITY_OFF::before {
		content: '\e8f5';
		font-family: 'Material Icons', sans-serif;
	}

	.edi-icon-PASTE::before {
		content: '\e14f';
		font-family: 'Material Icons', sans-serif;
	}
}
