@mixin color($color) {
	&::before {
		background-color: $color;
	}
	&::after {
		color: $color;
	}
}

.#{$prefix}-sidebar-notifications {
	&-backdrop {
		opacity: 0.2 !important;
	}

	.edi-ediweb-sidebar-header {
		.edi-ediweb-sidebar-header-text {
			padding-top: 2px;
			padding-left: 40px;
			@include typography__heading_02();
			color: #000000;
		}
	}

	.#{$prefix}-sidebar-body {
		background: url('images/sidebar_background.svg') center center no-repeat;
		background-color: #ffffff;
	}

	.x-grid-with-row-lines .x-grid-table {
		border-top: none;
	}

	.x-grid-with-row-lines {
		.x-grid-td {
			.x-grid-cell-inner {
				padding: 0;
			}
		}
	}

	.edi-ediweb-sidebar-buttons {
		z-index: 2;

		a.x-btn.x-btn-default-small {
			&.x-btn-over {
				background-color: #f5f9fc;
			}

			padding: 10px 7px 7px 10px;

			.x-btn-icon-el {
				color: #3d7dca;
			}

			.x-btn-inner {
				color: #3d7dca;
				letter-spacing: 0.1px;
			}
		}
	}

	.#{$prefix}-sidebar-floating-update-button {
		position: absolute !important;
		top: 88px !important;
		left: 111px !important;
		width: 200px !important;
		letter-spacing: 0.16px;
		border-radius: 4px;
		padding: 10px 12px;
		background-color: #3d7dca;

		.x-btn-inner,
		.x-btn-glyph {
			color: #ffffff;
		}
	}

	.#{$prefix}-notification {
		box-shadow: none;

		&-read {
			background-color: #f3f4f4;
		}

		&-unread {
			background-color: #ffffff;
		}
	}

	.ui-table {
		//строка заголовков колонок
		.x-grid-header-ct {
			border-bottom: 0px !important;
			min-height: auto;
		}

		.x-grid-cell {
			padding: 0 !important;
		}

		&-empty-text-with-image {
			width: 100%;
			&-icon {
				&-notifications-sidebar {
					background-image: url('images/emptyGrids/notifications_sidebar_empty_image.png');
				}
			}
		}
	}
}

.#{$prefix}-notification {
	padding: 4px;
	background-color: #fff;
	box-shadow: 0 4px 8px rgba(187, 189, 191, 0.25);
	border-radius: 4px;

	&-read {
		.#{$prefix}-notification-color {
			&-GREEN,
			&-RED,
			&-ORANGE,
			&-BLUE {
				@include color(#bbbdbf);
			}
		}

		.#{$prefix}-notification-date {
			color: #8e9295;
		}
	}

	&-wrap {
		padding: 14px 8px 4px 8px;
		padding-left: 72px;
		position: relative;
		white-space: normal;

		&::before {
			content: '';
			width: 4px;
			position: absolute;
			left: 4px;
			top: 12px;
			bottom: 12px;
			border-radius: 10px;

			background: #00afab;
		}

		&::after {
			position: absolute;
			top: 50%;
			left: 24px;
			transform: translate(0, -50%);
			font-family: 'Material Icons', sans-serif;
			font-size: 20px;

			content: '\e645';
			color: #00afab;
		}
	}

	&-icon {
		&-NEW_INVITATION,
		&-NEW_STATUS_OF_INVITATION {
			&::after {
				content: '\e554';
			}
		}

		&-INFORMATION {
			&::after {
				content: '\e645';
			}
		}

		&-NEW_DOCUMENT,
		&-NEW_STATUS_OF_DOCUMENT,
		&-TARIFFS,
		&-POA_FOR_SIGNING,
		&-POWER_OF_ATTORNEY,
		&-TARIFF_EXPIRED_NOTIFY,
		&-DSF_INVOICE_TARIFF_EXPIRES,
		&-DSF_INVOICE_TARIFF_ANNUL,
		&-DSF_INVOICE_FP_EXPIRES,
		&-EMPLOYEE_CERT_SIGN_ATTEMPT,
		&-REGISTRATION_ACCEPTANCE,
		&-DSF_ONBOARDING {
			&::after {
				content: '\e24d';
			}
		}

		&-NEW_CONNECTED_PRODUCT {
			&::after {
				content: '\e146';
			}
		}

		&-PARTNERSHIP {
			&::after {
				content: '\e7ef';
			}
		}

		&-ON_APPROVAL,
		&-REJECTED,
		&-REVOKED,
		&-APPROVE_NEXT_STAGE,
		&-SIGN_NEXT_STAGE,
		&-APPROVED,
		&-REASSIGN_NEXT_STAGE,
		&-EMPLOYEE_ON_APPROVAL,
		&-EMPLOYEE_APPROVE_NEXT_STAGE,
		&-EMPLOYEE_SIGN_NEXT_STAGE,
		&-EMPLOYEE_APPROVED,
		&-EMPLOYEE_SIGNED,
		&-EMPLOYEE_COMPLETED,
		&-EMPLOYEE_REJECTED,
		&-EMPLOYEE_REVOKED {
			&::after {
				content: '\e903';
				font-family: 'EdiwebIcons', sans-serif;
			}
		}
	}

	&-color {
		&-GREEN {
			@include color(#00afab);
		}

		&-RED {
			@include color(#e82000);
		}

		&-ORANGE {
			@include color(#ffa400);
		}

		&-BLUE {
			@include color(#3d7dca);
		}
	}

	&-date {
		display: block;
		@include typography__caption_01();
		color: #a5a8ab;
		margin-bottom: 8px;
	}

	&-title {
		display: block;
		@include typography__heading_01();
		color: $color-dark;
		margin-bottom: 8px;
	}

	&-text {
		display: block;
		@include typography__body-long_01();
		color: $color-dark;
		margin-bottom: 8px;
	}

	&-links {
		& a {
			padding-left: 0;

			&.x-btn,
			&.x-btn.x-btn-default-small.x-btn-over {
				background-color: transparent;

				.x-btn-button {
					.x-btn-inner-default-small {
						@include typography__body-short_02();
						color: #3d7dca;
						padding-left: 0;
					}
				}
			}
		}
	}
}

@keyframes notification-loader-animation {
	0% {
		transform: translate(-50%, -50%) rotate(0deg);
	}
	100% {
		transform: translate(-50%, -50%) rotate(360deg);
	}
}

.notification-button__loading::before {
	content: '';
	position: absolute;
	top: 50%;
	left: 50%;
	width: 20px;
	height: 20px;
	border-radius: 100%;
	border: 4px solid;
	border-color: #ffffff #ffffff #ffffff transparent;
	opacity: 0.5;
	animation: notification-loader-animation 1.2s linear infinite;
}
