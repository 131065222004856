@use '@UIkit/styles/typography/text';
@import '@UIkit/styles/global/Colors/Variables';

BODY.edi-viewport {
	&.edi-viewport-with-background {
		background-image: url('@Themes/ediweb/images/backgrounds/back_ediweb.jpg');
		position: static !important;
		z-index: 10;
	}

	//сделаем фон окна логина ССО был прозрачный, что бы не подстаривать размеры фрейма под окно внутри, т.к. они меняются
	//на самой странице ССО надо так же вырубить фоны, иначе толку ноль от этой затеи
	.edi-login-sso-window {
		background-color: transparent;
		box-shadow: none;

		.x-panel-body {
			background-color: transparent;

			iframe {
				body {
					background-color: transparent;
				}
			}
		}
	}
}

.ui-login {
	&-label {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
	}
	&-logo {
		width: 140px;
		height: 16px;
		background-image: url(@Themes/ediweb/images/EdiwebID_logo.svg);
		background-repeat: no-repeat;
		background-position: center center;
		background-size: auto 16px;

		&.left {
			background-position: left;
		}
	}

	&-info {
		width: 100%;
		height: 96px;
		background-repeat: no-repeat;
		background-position: center center;
		background-size: auto 96px;
	}

	&-header {
		position: relative;
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 100%;
		padding: 0 64px;

		&-logo {
			position: absolute;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);

			width: 140px;
			height: 16px;
			background-image: url(@Themes/ediweb/images/new_ediweb_logo.svg);
			background-repeat: no-repeat;
			background-position: center center;
			background-size: auto 16px;
		}
		&-text {
			@extend .caption_01;
			color: $color-grey-50;
		}
	}

	&-footer {
		text-align: center;

		&-text {
			@extend .caption_01;
			color: $color-grey-50;
		}
	}
}

.edi-viewport-header {
	left: 50% !important;
	transform: translateX(-50%);
	border-radius: 0 0 80px 80px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.edi-viewport-footer {
	top: auto !important;
	bottom: 0px;
	height: auto !important;
	background-color: transparent;

	.x-panel-body {
		height: auto !important;
		background-color: inherit;
	}
}

// данный оверрайд нужно удалить после переноса uikit в админку
.edi-viewport .x-form-item-label-default {
	color: var(--color-grey-50);
}
