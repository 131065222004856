.edi-viewport {
	.x-tab-bar {
		.x-tab {
			$this: &;
			&.edi-module-tab-button-main {
				width: 52px;

				&.x-tab {
					.#{$prefix}-icon-home {
						position: relative;
						background-image: url('images/icons/home.svg');
						width: 20px;
						height: 20px;
						top: -1px;
						margin-right: -10px;
					}
					&-active {
						width: auto !important;

						.#{$prefix}-icon-home {
							margin-right: 16px;
						}
					}
				}
			}
		}
	}
}
