.edi-viewport {
	.edi-state-container-ARCHIVED,
	.edi-state-container-ARCHIVAL,
	.edi-state-container-AMEND,
	.edi-state-container-CORRECTION,
	.edi-state-container-POWER_OF_ATTORNEY_REVOKED,
	.edi-state-container-POWER_OF_ATTORNEY_EXPIRED {
		border-color: #1d252c;

		.status-COMPLETED,
		.status-ARCHIVAL,
		.status-AMEND,
		.status-CORRECTION,
		.status-POWER_OF_ATTORNEY_REVOKED,
		.status-POWER_OF_ATTORNEY_EXPIRED {
			color: #1d252c;
		}
	}

	.edi-state-container-UTOCH,
	.edi-state-container-ON_APPROVAL_BY_RECEIVER,
	.edi-state-container-ON_APPROVAL {
		border-color: #3d7dca;

		.status-UTOCH,
		.status-ON_APPROVAL_BY_RECEIVER,
		.status-ON_APPROVAL {
			color: #3d7dca;
		}
	}

	.edi-state-container-REJECT,
	.edi-state-container-REVOKED,
	.edi-state-container-EXPIRED,
	.edi-state-container-POWER_OF_ATTORNEY_STATUS_errors {
		border-color: #e82000;

		.status-REJECT,
		.status-REVOKED,
		.status-EXPIRED {
			color: #e82000;
		}
	}

	.edi-state-container-EXPECTATION {
		border-color: #ff9400;

		.status-EXPECTATION {
			color: #ff9400;
		}
	}

	.edi-state-container-COMPLETED,
	.edi-state-container-APPROVED,
	.edi-state-container-POWER_OF_ATTORNEY_STATUS_withoutErrors {
		border-color: #00ac2d;

		.status-COMPLETED,
		.status-APPROVED {
			color: #00ac2d;
		}
	}

	.edi-state-container-REVOKE {
		border-color: #8e9295;

		.status-REVOKE {
			color: #8e9295;
		}
	}

	.edi-state-container-NOT_CONFIRMED {
		border-color: #ff9400;

		.status-NOT_CONFIRMED {
			color: #ff9400;
		}
	}

	.edi-state-container-CONFIRMED {
		border-color: #00ac2d;

		.status-CONFIRMED {
			color: #00ac2d;
		}
	}

	.edi-state-container-DEACTIVATED,
	.edi-state-container-DELETED {
		border-color: #e82000;

		.status-DEACTIVATED,
		.status-DELETED {
			color: #e82000;
		}
	}

	.edi-document-header-panel-row {
		.edi-state-container-document {
			border-radius: 20px;
			border-style: solid;
			border-width: 1px;
			padding: 3px 6px;
			display: inline-block;
			white-space: nowrap;
		}

		.ui-fieldblock {
			label {
				@extend .body-short_01;
			}
		}
	}
}
