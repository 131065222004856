.edi-acceptance-window {
  &-title {
    @include typography__heading_02();
    text-align: center;
  }
  &-rightHeader-container {
    background-color: transparent!important;
    margin-bottom: 32px!important;
  }

  &-subtitle-info-container {
    padding-top: 8px;
    background-color: transparent!important;

    label {
      white-space: nowrap;
    }
  }

  &-content {
    @include typography__body-long_01();

    ol {
      counter-reset: item;
      padding: 2px 0 0 0;
      display:inline-block;
    }

    li {
      display: block;
      @include typography__body-long_01();
      margin: 8px 0;
    }
    li:last-child {
      margin: 8px 0 0 0;
    }

    li:before {
      content: counters(item, ".") ". ";
      counter-increment: item;
      margin-left: -30px;
    }

    li > b {
      @include typography__heading_01();
      display: contents;
    }
  }
  &-content-org-info {
    .line:not(:last-child) {
      padding-bottom: 8px;
    }
  }
}

a {
  text-decoration: none!important;
  color: #3D7DCA;
}