.x-panel-bodyWrap {
	overflow: visible;
}

.edi-form-panel-description {
	@include typography__body-short_01();
	position: relative;
	display: block;

	&::after {
		position: absolute;
		content: '\e7f4';
		display: inline-block;
		font-size: 20px;
		margin-left: 10px;
		font-family: 'Material Icons', sans-serif;
	}
}