@font-face {
	font-family: 'EdiwebIcons';
	font-style: normal;
	font-weight: 400;
	src: url(fonts/ediweb_icons/icomoon.eot);
	src: url(fonts/ediweb_icons/icomoon.eot?gq6fu8#iefix) format('embedded-opentype'),
		url(fonts/ediweb_icons/icomoon.woff) format('woff'), url(fonts/ediweb_icons/icomoon.ttf) format('truetype'),
		url(fonts/ediweb_icons/icomoon.svg?gq6fu8#icomoon) format('svg');
}
// Global variables
$global: 'edi';
// Variables project's
$project: 'ediweb';
$prefix: #{$global}-#{$project};
$main_prefix: #{$global}-main;

@import '@UIkit/styles/global/Colors/Variables';
@import '@UIkit/styles/typography/index';

@import './scss/variables';
@import './scss/mixins';
@import './scss/overrides';
@import './scss/edi-icons';
@import './scss/alert';
@import './scss/intro';
@import './scss/project';
@import './scss/main-panel';
@import './scss/product_cards';
@import './scss/banner';
@import './scss/login';
@import './scss/confirm';
@import './scss/org_settings';
@import './scss/sidebar';
@import './scss/toast';
@import './scss/dialog-window';
@import './scss/agreement-window';
@import './scss/acceptance-window';
@import './scss/fieldset';
@import './scss/file-dropper';
@import './scss/notifications';
@import './scss/ediweb.toast';
@import './scss/file_modal';
@import './scss/panel';
@import './scss/grid';
@import './scss/vertical_stepper';
@import './scss/connecting-dropcat-modal';
@import './scss/form';
@import './scss/tour';
//удалить файл в 1.0.19
//@import './scss/new_toast';
@import './scss/progress_bar';
@import './scss/tariffs';
@import './scss/carousel';
