.ui-carousel {
	overflow: hidden;
	&-cards {
		border-radius: 4px !important;

		&-item:hover {
			cursor: pointer;
		}
	}
	&-dots {
		.x-autocontainer-innerCt {
			display: flex;
			flex-wrap: nowrap;
			flex-direction: row;
			justify-content: center;
		}

		&-item {
			cursor: pointer;
			margin-left: 1px;
			.x-btn-wrap {
				border: 1px solid #3d7dca;
				border-radius: 50px !important;
			}

			.ui-button-glyph {
				border: 1px solid #3d7dca;
				border-radius: 50px;
				line-height: 22px !important;
				padding-right: 23px;
			}
		}
		&-item:hover {
			background-color: transparent !important;
		}
	}
}