.#{$prefix}-window-alert {
	@mixin header {
    & .x-window-header {
      &-body {
        @content;
      }
    }
  }

  &-title {
    display: block;
    @include typography__body-short_02();
  }

	& .x-window-body {
    & .x-form-display-field {
	    width: 386px !important;
      padding: 0 24px;
      display: block;
	    @include typography__body-short_02();
      text-align: center;
      color: #1C1C1C;
    }
  }

  & .x-window-header {
    margin: 16px 24px 0 24px !important;
  }
  & .x-window-body {
    margin-top: -10px !important;
  }

	&-info {
		@include header {
			background: url('images/icons/info.png') center center no-repeat;
			background-size: 20px auto;
		}
	}
	&-warn {
		@include header {
			background: url('images/icons/warn.png') center center no-repeat;
			background-size: 20px auto;
		}
	}
	&-error {
		@include header {
			background: url('images/icons/error.png') center center no-repeat;
			background-size: 20px auto;
		}
	}
}